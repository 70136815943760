#header {
    background: #2885a1 url(../images/hero-image.jpg);
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.about-elsi {
    height: 100vh;
    background: #2885a1 url(../images/parallax-einesco.jpg) top center;
    background-size: cover;
    padding-bottom: 60px;
    background-repeat: no-repeat;
}

.serminar-elsi {
    height: 100vh;
    background-size: cover;
    padding-bottom: 60px;
    background-repeat: no-repeat;
    background: #112258;
    background: -moz-linear-gradient(top, #112258 0%, #2885a1 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #112258), color-stop(100%, #2885a1));
    background: -webkit-linear-gradient(top, #112258 0%, #2885a1 100%);
    background: -o-linear-gradient(top, #112258 0%, #2885a1 100%);
    background: -ms-linear-gradient(top, #112258 0%, #2885a1 100%);
    background: linear-gradient(to bottom, #112258 0%, #2885a1 100%);
    max-height: 600px;
}

div.description p {
    color: #d8d8d8;
    text-shadow: 2px 2px 2px #2885a1;
    font-size: 1.25em;
}

div.description {
    margin-top: calc(50vh - 230px);
}

div.about-elsi b {
    color: #00ade1;
}

div.about-elsi h1 {
    display: inline-block;
    position: relative;
    top: auto;
    left: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -50vw;
    z-index: 1;
    padding: 10px 10px 10px 50vw;
    text-transform: uppercase;
    border-color: #eee;
    background-color: #eee;
    color: #000;
    font-weight: bold;
}

div.about-elsi h1::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -80px;
    width: 0;
    height: 0;
    border-top: 75px solid #eee;
    border-right: 80px solid transparent;
}

div.serminar-elsi b {
    color: #00ade1;
}

div.serminar-elsi h1 {
    display: inline-block;
    position: relative;
    top: auto;
    left: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -50vw;
    z-index: 1;
    padding: 10px 10px 10px 50vw;
    text-transform: uppercase;
    border-color: #eee;
    background-color: #eee;
    color: #000;
    font-weight: bold;
}

div.serminar-elsi h1::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -80px;
    width: 0;
    height: 0;
    border-top: 75px solid #eee;
    border-right: 80px solid transparent;
}

div#seminar-pics div.item {
    height: 66vh;
    background-color: rgba(0, 0, 0, 0.3);
    background-size: cover !important;
    background-position: 50% 50% !important;
    border-radius: 10px;
    max-height: 440px;

}


div#seminar-pics div.item p {
    position: absolute;
    color: #fff;
    background: #000;
    background: -webkit-linear-gradient(transparent, #000);
    background: -o-linear-gradient(transparent, #000);
    background: -moz-linear-gradient(transparent, #000);
    background: linear-gradient(transparent, #000);
    width: 100%;
    padding: 10px;
    bottom: 0px;
    font-size: 20px;
    margin: 0px;
    padding-top: 40px;
    border-radius: 10px;
}

div#seminar-pics div.item span {
    display: inline-block;
    position: relative;
    top: auto;
    left: auto;
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: -2%;
    z-index: 1;
    padding: 10px 10px 10px 4.3478%;
    text-transform: uppercase;
    border-color: #eee;
    background-color: #2885a1;
    color: #fff;
    font-weight: bold;
}

div#seminar-pics div.item span::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -50px;
    width: 0;
    height: 0;
    border-top: 40px solid #2885a1;
    border-right: 50px solid transparent;
}

div#seminar-pics div.item i {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #2885a1;
    padding: 11px 10px;
    border-radius: 50%;
    color: #fff;
    font-size: 2em;
    border: 2px solid;
}

div#seminar-pics div.item i em {
    position: absolute;
    font-size: 14px;
    background-color: #fff;
    padding: 5px 4px;
    border-radius: 50%;
    bottom: -6px;
    left: -4px;
    font-family: sans-serif;
    font-weight: bold;
    color: #2885a1;
}

.owl-prev {
    position: absolute;
    width: 0px;
    height: 0px;
    border-top: 20px solid transparent;
    border-right: 30px solid rgb(255, 255, 255);
    border-bottom: 20px solid transparent;
    color: transparent;
    top: calc(50% - 20px);
    left: -20px;
    cursor: pointer;
}

.owl-next {
    position: absolute;
    width: 0px;
    height: 0px;
    border-top: 20px solid transparent;
    border-left: 30px solid rgb(255, 255, 255);
    border-bottom: 20px solid transparent;
    color: transparent;
    top: calc(50% - 20px);
    right: -20px;
    cursor: pointer;
}

div.safety-videos b {
    color: #00ade1;
}

div.safety-videos h1 {
    display: inline-block;
    position: relative;
    top: auto;
    left: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -50vw;
    z-index: 1;
    padding: 10px 10px 10px 50vw;
    text-transform: uppercase;
    border-color: #eee;
    background-color: #eee;
    color: #000;
    font-weight: bold;
}

div.safety-videos h1::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -80px;
    width: 0;
    height: 0;
    border-top: 75px solid #eee;
    border-right: 80px solid transparent;
}

.safety-videos {
    height: auto;
    background-size: cover;
    padding-bottom: 60px;
    background-repeat: no-repeat;
    background: #2885a1;
    background: -moz-linear-gradient(top, #2885a1 0%, #ffcd71 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #2885a1), color-stop(100%, #ffcd71));
    background: -webkit-linear-gradient(top, #2885a1 0%, #ffcd71 100%);
    background: -o-linear-gradient(top, #2885a1 0%, #ffcd71 100%);
    background: -ms-linear-gradient(top, #2885a1 0%, #ffcd71 100%);
    background: linear-gradient(to bottom, #2885a1 0%, #ffcd71 100%);
}

.mb-20 {
    margin-bottom: 20px;
}

div#safety-videos div.item div.col-md-8 p {
    position: absolute;
    color: #fff;
    background: #000;
    background: -webkit-linear-gradient(transparent, #000);
    background: -o-linear-gradient(transparent, #000);
    background: -moz-linear-gradient(transparent, #000);
    background: linear-gradient(transparent, #000);
    width: calc(100% - 30px);
    padding: 10px;
    bottom: 0px;
    font-size: 20px;
    margin: 0px;
    padding-top: 40px;
    border-radius: 10px;
}

div#safety-videos div.item div.col-md-4 p {
    position: absolute;
    color: #fff;
    background: #000;
    background: -webkit-linear-gradient(transparent, #000);
    background: -o-linear-gradient(transparent, #000);
    background: -moz-linear-gradient(transparent, #000);
    background: linear-gradient(transparent, #000);
    width: calc(100% - 30px);
    padding: 10px;
    bottom: 0px;
    margin: 0px;
    padding-top: 40px;
    border-radius: 10px;
}

.video-container {
    background-color: rgba(0, 0, 0, 0.3) !important;
    background-size: cover !important;
    background-position: 50% 50% !important;
    border-radius: 10px;
    min-height: 100%;
}

.preloader-img {
    width: 100px !important;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    z-index: -1;
}

.levels {
    background-image: url('http://labsafetyinstitute.com/public/mockup/images/level-parallax.jpg');
    background-size: cover;
    background-attachment: fixed;
    padding: 0px;
}

.level-overlay {
    background: rgba(255, 0, 0, 0.4);
    background: -webkit-linear-gradient(right top, rgba(255, 0, 0, 0.4), rgba(0, 128, 0, 0.5), rgba(255, 165, 0, 0.5));
    background: -o-linear-gradient(bottom left, rgba(255, 0, 0, 0.4), rgba(0, 128, 0, 0.5), rgba(255, 165, 0, 0.5));
    background: -moz-linear-gradient(bottom left, rgba(255, 0, 0, 0.4), rgba(0, 128, 0, 0.5), rgba(255, 165, 0, 0.5));
    background: linear-gradient(to bottom left, rgba(255, 0, 0, 0.4), rgba(0, 128, 0, 0.5), rgba(255, 165, 0, 0.5));
    width: 100%;
    height: auto;
    position: relative;
}

.section-title, .section-description {
    z-index: 1;
    position: relative;
    color: #FFF !important;
}

.section-description {
    text-align: left;
    text-transform: uppercase;
}

.container.categories h4 {
    border: 2px solid;
    padding: 10px 20px;
    width: 200px;
    margin: 10px auto;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.05);
    text-transform: uppercase;
}

.level {
    padding-top: 80px;
}

.container.categories {
    padding-bottom: 80px;
}

.site-title img {
    margin: calc(50vh - 200px) auto !important;
}

#safety-videos-mobile{
    display: none;
}

div#safety-videos-mobile div.item p {
    position: absolute;
    color: #fff;
    background: #000;
    background: -webkit-linear-gradient(transparent, #000);
    background: -o-linear-gradient(transparent, #000);
    background: -moz-linear-gradient(transparent, #000);
    background: linear-gradient(transparent, #000);
    width: 100%;
    padding: 10px;
    bottom: 0px;
    font-size: 20px;
    margin: 0px;
    padding-top: 40px;
    border-radius: 10px;
}
@media (max-width:992px) {
    div#safety-videos div.item div.col-md-4 p{
        font-size: 20px;
    }
}
@media (max-width:767px) {
    div.serminar-elsi h1, div.about-elsi h1, div.safety-videos h1 {
        font-size: 20px;
    }
    div.serminar-elsi h1::after, div.safety-videos h1::after, div.about-elsi h1::after {
        border-top: 42px solid #eee;
    }
    .serminar-elsi{
        max-height: 530px;
    }
    #safety-videos{
        display: none;
    }
    #safety-videos-mobile{
        display: block;
    }
    .about-elsi {
        min-height: 600px;
    }
    .section-title {
        text-align: center;
        font-size: 30px !important;
    }
    .section-description {
        text-align: center;
        font-size: 16px !important;
    }
}

@media (max-width:430px) {
    div.description {
        margin-top: 0px;
    }
    div.description p {
        text-shadow: 2px 2px 2px #000;
        font-size: 1em;
    }
}