@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);

@import url(https://fonts.googleapis.com/css?family=Numans);

@font-face {
    font-family: keepcalm;
    src: url(../fonts/KeepCalm-Medium.ttf);
}

@font-face {
    font-family: staccato;
    src: url(../fonts/staccato.ttf);
}

@font-face {
    font-family: bebas;
    src: url(../fonts/bebas-regular.otf);
}

@font-face {
    font-family: sourcesanpro;
    src: url(../fonts/SourceSansPro.otf);
}