@import url(https://fonts.googleapis.com/css?family=Arimo);

body{
    background-color: #FFF;
    height: 100vh;
}

.wrapper{
    height: auto;
}

.header .logo{
    margin-top: 5px;
    margin-left: 15px;
    float: left;
}

.header .navbar-collapse{
    padding: 0;
}

.main-nav.navbar-default .navbar-nav > li > a:hover{
    background: #c1d0d9;
    color: #FFF;
}

#main-nav{
    background: #207691;
    padding: 15px;
}

.main-nav.collapse li{
    padding: 0 8px;
}

.main-nav.collapse a{
    font-family: sourcesanpro;
    color: #EFEFEF !important;
}


.main-nav.collapse > ul a{
    font-size: 1.1em;

}

button#user-options{
    background: #84d3ec;
    border: 1px solid #e7e7e7 !important;
    color: #207691 !important;
    box-shadow: none;
    padding: 10px 15px;
    margin-top: 5px;
    border: 0 !important;
}

.main-nav.nav.navbar-nav li.active a{
    background: #356ba1 !important;
}

a.logo img{
    width: 100%;
}

#sidebar{
    margin-top: 10px;
}

#sidebar .panel-title{
    font-weight: bold;
    text-align: center;
}

.fixed{
    position: fixed;
    width: 14%;
}

.right-panel{
    padding: 0 !important;
    border-left: 1px solid #edecec;
    min-height: 500px;
    height: auto;
}

#main{
    background: #FFF;
    min-height: 600px;
}

.navbar{
    border: 0;
    border-radius: 0;
    margin: 0 !important;
   /* height: 60px;*/
}

.navbar-header img{
    width: 36px;
    margin-right: 8px;
    float: left;
}

a.navbar-brand{
    font-family: 'bebas', Times, serif;
    font-size: 3em !important;
}

.one-form-panel{
    margin-top: 100px;
}

.timer{
    margin: 0 auto;
}

#timer{
    background: #6baabe;
    color: #EFEFEF;
    border: 5px solid #FAFAFA;
    margin-bottom: 10px;
}

#content{
    padding: 20px;
    margin-bottom: 20px;
    min-height: 60vh;
}

.bordered{
    border: 1px solid #edecec;
}

.padded{
    padding: 10px;
}

.padded-2{
    padding: 20px;
}

.padded-3{
    padding: 30px;
}

.progress-box{
    background: rgba(200,200,200,0.4);
    margin-top: 40px;
}



a.navbar-brand{
   color: #FFF !important;
}

.question-box{
    margin-top: 10px;
    padding: 10px;
    border-bottom: 1px solid #EEE;
}

.div-error{
    margin: 5px 0;
}

/*************************/

.test-item{
    padding-bottom: 10px;
}

.test-item-no{
    text-align: center;
    padding: 5px;
    background: #ffdae2;
    border-radius: 50px;
    margin-bottom: 5px;
}

.test-item-text{
    margin-bottom: 10px;
}

.test-item-choices{
    margin: 10px;
}

.test-item-choice{
    margin: 5px;
    padding: 5px;
}

.result{
    margin: 0;
}

.answered{
    background: #58F5C8;
}

.marked{
    font-weight: bold;
    padding: 5px;
    border: 2px solid #0086b3;
}

.test-item-no{
    cursor: pointer;
}

/******************************/

.answer-overview{
    padding: 3px;
}

.answer-overview-title{
    color: #2ea4ac;
    font-weight: bold;
}

.answer-overview-item.o-marked{
    border-color: #aadfea;
}

.answer-overview-item.o-answered{
    color: #38bd97;
}

.answer-overview-item, .page-no-seen{
    float: left;
    padding: 3px;
    margin-bottom: 3px;
    margin-right: 3px;
    min-width: 20px;
    color: #f25e5e;
}

.test-result-icon{
    width: 40px;
    margin: 20px auto 0;
}

.score-display{
    font-size: 46pt;
    text-align: center;
}

.score-display.failed{
    color: #fa0000;
}

.score-display.passed{
    color: #009900;
}

#user-options{
    background: #328ECF;
    color: #fff6de !important;
    box-shadow: none;
    border: none;
    border-radius: 1;
}

#user-options .glyphicon-user{
    margin-right: 5px;
}

.main-nav .dropdown-menu{
    background: rgba(240,240,240,0.8);
    padding: 0 !important;
    text-align: center;
}

.main-nav .dropdown-menu > li{
    padding: 0;
}

.main-nav .dropdown-menu > li a{
    padding: 10px;
    color: #16496b !important;
}

.main-nav .dropdown-menu .divider{
    margin: 0;
    background-color: rgba(220,220,220,0.8);
}

.main-nav .dropdown-menu a:hover{
    background: #c1d0d9;
}



/********* Admin user **********************/
.avatar{
    border: 1px solid rgba(200,200,200,0.8);
}

.common-form{
    margin-top: 20px;
}

.control-box{
    width: 100%;
    /*background: rgba(200,200,200,0.1);*/
    padding: 20px;
    border-bottom: 1px solid #edecec;
}

.sidebar-section{
    border: 1px solid rgba(230,230,230,0.3);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    margin-bottom: 10px;
}

.sidebar-section-title{
    background: rgba(230,230,230,0.2);
    padding: 15px;
    font-weight: bold;
    color: #0086b3;
    border-bottom: 1px solid #edecec;
}

.sidebar-section-content{
    margin: 15px;
    color: #2ea4ac;
    font-family: 'Numans', sans-serif !important;
    font-size: 11pt;
}

.notification-box{
    margin: 10px;
}


/**************** Dialog box ************************/

.dialog-box-bg {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;
}

.dialog-box{
    text-align: center;
    height: 200px;
    margin-top: 150px;
    display: none;
}

.dialog-box ul{
    text-align: center;
    margin: 15px 0 10px;
}

.dialog-box .message{
    font-weight: bold;
}

.dialog-box input{
    margin-top: 10px;
    text-align: center;
}

/************************************************************/

a.table-head{
    color: #054589;
}

a.table-head.active{
    color: #0086b3;
    font-weight: bold;
}

.question-image{
    display: none;
    margin: 5px;
}

.choice-box{
    border-left: 1px solid rgba(200,200,200,0.4);
    border-right: 1px solid rgba(200,200,200,0.4);
    border-bottom: 1px solid rgba(200,200,200,0.4);
    padding: 15px;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
    padding: 10px;
}

.option-letter, .circled-item{
    background: rgba(0,180,250,0.2);
    border-radius: 100px;
    padding: 15px 20px;
}

.page-no-seen.chosen{
    color: #0F9E5E;
}

.progress-title{
    color: #0086b3;
    padding: 8px;
    border-radius: 5px;
}

.progress-level{
    padding: 10px;
}

textarea{
    resize: none;
}

/*************/

button.action-dropdown{
    margin-right: 10px;
    color: #0086b3;
    border-color: rgba(240,240,240,0.8) !important;
}

.choices .dropdown-menu{
    background:  rgba(245,245,245,1);
}

.choices .dropdown-menu a{
    color: #222222;
}

.choices .dropdown-menu a:hover{
    background: rgba(230,230,230,1) !important;
}

.deletable-image-box{
    border: 1px solid rgba(245,245,245,0.8);
    padding: 5px;
}

.deletable-image-box button.close{
    background: #FFF;
    padding: 4px 6px;
    border-radius: 100%;
    position: absolute;
    float: right;
    top: 10px;
    margin-left: 10px;
}

.loading-box-bg {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;
}

.loading-box{
    width: 350px;
    margin: 80px auto 0;
}

.jumbotron{
    margin-top: 20px;
    padding: 30px !important;
    text-align: center;
    box-shadow: none;
    border: 3px solid rgba(230,230,230,0.8);
    border-radius: 0;
}

/*****************/

#header{
    height: 100vh;
    background: #207691;
    padding-bottom: 60px;
}

.site-title img{
    margin: 15vh auto;
}

.levels{
    background: #FAFAFA;
    padding-top: 80px;
    padding-bottom: 30px;
    miin-height: 650px;
}

.section-title{
    font-size: 4.5vw !important;
    font-weight: bold;
    color: #207691 !important;
}

.section-description{
    font-size: 16pt;
    color: #207691;
    text-align: center;
}

.container.categories{
    margin-top: 60px;
}

.container.categories h4{
    margin-top: 30px;
    color: #207691;
    text-align: center;
}

.quiz-categories{
    margin: 40px 0;
}

.container-zig {
    position: relative;
    padding: 0 8px 32px 8px;
    background: url('images/texture2.png');
}

.container-zig:after {
    background: linear-gradient(135deg, #FAFAFA 12px, transparent 0), linear-gradient(-135deg, #FAFAFA 12px, transparent 0);
    background-position: top;
    background-repeat: repeat-x;
    background-size: 32px 32px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 32px;
}

.options{
    background: url('images/texture2.png') #c4c4c4;
    padding-bottom: 50px;
    border-bottom: 30px solid #207691;
}

.options .container{
    margin-top: 150px;
    margin-bottom: 110px;
}

.options .or-circle{
    margin-top: 30px;
    height: 100px;
    width: 100px;
    border: 10px solid #207691;
    color: #207691;
    border-radius: 100%;
    font-size: 28pt;
    font-weight: bold;
    text-align: center;
    line-height: 2.1em;
    background: #EFEFEF;
}

.option-box{
    padding: 20px;
    height: auto;
    border: 6px solid #6baabe;
    border-radius: 15px;
    font-family: 'Tahoma', Arial, serif;
    font-size: 46pt;
    line-height: 1em;
    text-align: center;
    color: #FFF;
}

.register{
    background: linear-gradient(17deg, #EFEFEF 360px, transparent 0); /*linear-gradient(-45deg, #c4c4c4 80px, transparent 0), */
    background-position: bottom;
    min-height: 600px;
    padding-bottom: 50px;
}

form.form-horizontal.flat input{
    border-radius: 0;
}

form.form-horizontal.flat label{
    color: #207691;
    text-align: right;
}

.panel.flat{
    border: 1px solid #c4c4c4;
    border-radius: 0;
    box-shadow: none;
}
.panel.flat .panel-heading{
    background: none;
    padding-top: 10px;
    color: #207691;
    font-size: 18pt;
    text-align: center;
    border: none;
    border-radius: 0;
}

.panel.registration.flat{
    margin: 50px 0;
}

.panel.login{
    margin: 70px 20px 20px;
    padding: 30px 30px 0;
}
.panel.login p{
    font-size: 10pt;
}

.panel.about-us{
    margin: 70px 40px 30px 40px;
    text-align: justify;
}
.panel.about-us .panel-body{
    padding: 30px 30px 60px;
}

form.flat .btn{
    border-radius: 0 !important;
}

.btn.flat{
    border-radius: 0;
    background: #207691;
    color: #FFF;
}

.fake-input{
    border: 1px solid #CCC;
    padding: 8px;
}

footer{
    background: #207691;
    width: auto;
    padding: 30px;
}

footer p, footer h5{
    color: #FFF;
}

hr.style17 {
    border-top: 1px solid #c4c4c4;
    text-align: center;
    margin: 0;
}
hr.style17:after {
    content: '§';
    display: inline-block;
    position: relative;
    top: -14px;
    color: #8c8b8b;
    font-size: 18px;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    transform: rotate(60deg);
}

.nav-user-btn{
    margin: 0;
}

@media (min-width: 768px) {
    .main-nav.collapse > ul{ float: left; }
}

@media (min-width: 992px) {
    .main-nav.collapse > ul{ float: right; }
}


/******************************/

/*

RESPONSTABLE 2.0 by jordyvanraaij
  Designed mobile first!

If you like this solution, you might also want to check out the 1.0 version:
  https://gist.github.com/jordyvanraaij/9069194

*/
.responstable {
    margin: 1em 0;
    width: 100%;
    overflow: hidden;
    background: #FFF;
    color: #024457;
    border-radius: 10px;
    border: 1px solid #167F92;
}
.responstable tr {
    border: 1px solid #D9E4E6;
}
.responstable tr:nth-child(odd) {
    background-color: #EAF3F3;
}
.responstable th {
    display: none;
    border: 1px solid #FFF;
    background-color: #167F92;
    color: #FFF;
    padding: 1em;
}
.responstable th:first-child {
    display: table-cell;
    text-align: center;
}
.responstable th:nth-child(2) {
    display: table-cell;
}
.responstable th:nth-child(2) span {
    display: none;
}
.responstable th:nth-child(2):after {
    content: attr(data-th);
}
@media (min-width: 480px) {
    .responstable th:nth-child(2) span {
        display: block;
    }
    .responstable th:nth-child(2):after {
        display: none;
    }
}
.responstable td {
    display: block;
    word-wrap: break-word;
    max-width: 7em;
}
.responstable td:first-child {
    display: table-cell;
    text-align: center;
    border-right: 1px solid #D9E4E6;
}
@media (min-width: 480px) {
    .responstable td {
        border: 1px solid #D9E4E6;
    }
}
.responstable th, .responstable td {
    text-align: left;
    margin: .5em 1em;
}
@media (min-width: 480px) {
    .responstable th, .responstable td {
        display: table-cell;
        padding: 1em;
    }
}

h1 {
    font-family: Verdana;
    font-weight: normal;
    color: #024457;
}
h1 span {
    color: #167F92;
}

@media only screen and (min-width : 768px) {
    .login-container{
        float: right;
    }
}

.einesco{
    margin-right: 12px;
    margin-bottom: 3px;
}

/************************************************/

.breadcrumb-container{
    background: rgba(245, 245, 245, 1);
}

.breadcrumb{ background: none !important; margin: 10px;  }
.breadcrumb li {font-size: 14px;}
.breadcrumb a {color: rgba(66, 139, 202, 1);}
.breadcrumb a:hover {color: rgba(42, 100, 150, 1);}
.breadcrumb>li+li:before {color: rgba(204, 204, 204, 1); content: " \232A\00a0";}

ul#social-media i{
    font-size: 30pt;
    color: #EEE;
}